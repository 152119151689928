export const aboutPageContent = {
  featuredSectionOne: {
    imgUrl: "",
    title: "EU and volunteering",
    description:
      'European Union has strong interest in volunteering in the EU in general and many EU policies speak in this regard. A first-ever all-arround survey about volunteering in sport by GHK in 2010. provided a comprehensive analysis about volunteering in sport. Conclusion underlines that sector of sport needs volunteers while volunteers need training: better recognition and validation, better mobility with the EU and better skills "people skills" as well as skills to handle paper work. Urban Sport and Culture Volunteers Erasmus+ Sport project was created with this finding in mind.',
  },
  featuredSectionTwo: {
    imgUrl: "",
    title: "Urban sport and culture volunteers project",
    description:
      "The overall objective of the project is to increase employability of youth with fewer opportunities through volunteering at sport events. The project is carried out by a project consortium made of 7 partners from 6 European countries (one is a Partner country from war affected area) and a group of 19 people who represent NGOs from urban sport and culture scene. Besides this application within the project a comprehensive training for volunteers is developed as well as volunteering handbook. All three combined offer an overall support for both sport and culture event organisers and volunteers.",
  },
  featuredSectionThree: {
    imgUrl: "",
    title: "Objective and goal",
    description:
      "This application is primarily developed to facilitate volunteering at sport and culture events. Given the fact that many event organisers use inefficient and slow models for recruiting appropriate volunteers and volunteers mostly aren't aware on volunteering opportunities this application offers solution. The tangible issues with setting up fast real-time connection and communication between organisatiors and volunteers are solved in one place. This application aims to become a meeting point for all enthusiasts in love with festival culture, be it sport, music or any other form of urban event.",
    // list: [
    //   "Recruitment of volunteers.",
    //   "Implementing the non-formal education training.",
    //   "Organising volunteering experience at urban sport and culture events.",
    //   "Creating a huge promotional campaign.",
    // ],
  },
  aboutCardOne: {
    title: "Target audience",
    description:
      "Target group is every organisation which coordinates specialized events and every person who is willing to volunteer. There are two target groups of this application: 1) local civil society organisations – NGO's, sport clubs etc. and 2) music festivals, conferences and sport events like Pannonian Challenge, Simple Session, Urbano Dejanje, European Week of Sport or other EU sport, music or cultural events.",
    media: {
      src: "",
      alt: "A group of volunteers celebrating and posing for a picture.",
    },
    onPrimary: true,
  },
  aboutCardTwo: {
    title: "Partners and supporters",
    description:
      "The project will be carried out by a project consortium made of 7 partners from 6 European countries (one is a Partner country from war affected area) and a group of 19 people who represent NGOs from urban sport and culture scene.",
    media: {
      src: "",
      alt: "Two judges. One being serious and watching show, and other posing with a peace sign.",
    },
    onPrimary: true,
  },
};
