import { FeaturedSection } from "components/FeaturedSection/FeaturedSection";
import Layout from "components/layout/Layout";
import { SectionTitleDescription } from "components/SectionTitleDescription/SectionTitleDescription";
import React from "react";

import { AboutCard } from "components/AboutCard/AboutCard";

import "assets/styles/pages/about-page.scss";
import { ErasmusBanner } from "components/ErasmusBanner/ErasmusBanner";
import { graphql, PageProps } from "gatsby";

import { aboutPageContent } from "assets/data/about-page";
import { SEO } from "components/SEO";

interface DataType {
  pannonianChallengeOpportunity: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  urbanoDejanjeOpportunity: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  whyVolunteer: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  howVolunteer: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
  objectiveGoal: {
    childImageSharp: {
      fluid: {
        base64: string;
        srcWebp: string;
        srcSetWebp: string;
        originalImg: string;
        originalName: string;
      };
    };
  };
}

const About: React.FC<PageProps<DataType>> = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />

      <SectionTitleDescription
        title="About us"
        description="Your international volunteering network in urban sports and culture scene."
        onPrimary={false}
      />

      <FeaturedSection
        imgUrl={data.pannonianChallengeOpportunity}
        title={aboutPageContent.featuredSectionOne.title}
        description={aboutPageContent.featuredSectionOne.description}
      />

      <FeaturedSection
        imgUrl={data.urbanoDejanjeOpportunity}
        title={aboutPageContent.featuredSectionTwo.title}
        description={aboutPageContent.featuredSectionTwo.description}
      />

      <FeaturedSection
        imgUrl={data.objectiveGoal}
        title={aboutPageContent.featuredSectionThree.title}
        description={aboutPageContent.featuredSectionThree.description}
      />

      <article className="about-page section__wrapper--dark">
        <section className="about-cards">
          <div className="about-cards__container container" role="presentation">
            <AboutCard
              {...aboutPageContent.aboutCardOne}
              media={{ src: data.whyVolunteer, alt: "" }}
            />

            <AboutCard
              {...aboutPageContent.aboutCardTwo}
              media={{ src: data.howVolunteer, alt: "" }}
            />
          </div>
        </section>
      </article>

      <article className="section__wrapper">
        <SectionTitleDescription
          title="Expectations and main goals"
          description=""
          list={[
            "Strengthen the international volunteering network in urban sport and culture scene.",
            "Enhance, recognise and validate volunteers' skills and key competences.",
            "Help exchange good practices.",
          ]}
          onPrimary={false}
        />
      </article>

      <ErasmusBanner />
    </Layout>
  );
};

export const query = graphql`
  {
    pannonianChallengeOpportunity: file(
      name: { eq: "pannonian-challenge-opportunity" }
    ) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    urbanoDejanjeOpportunity: file(name: { eq: "urbano-dejanje-opportunity" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    whyVolunteer: file(name: { eq: "why-volunteer" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    howVolunteer: file(name: { eq: "how-volunteer" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
    objectiveGoal: file(name: { eq: "objective-and-goal" }) {
      childImageSharp {
        fluid {
          base64
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
        gatsbyImageData
      }
    }
  }
`;

export default About;
