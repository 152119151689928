import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import UscVolLogo from "assets/images/uscvol-logo.svg";

import * as featuredSection from "./FeaturedSection.styles";
interface Props {
  imgUrl: string | any;
  title: string;
  description: string;
  list?: string[];
}

export const FeaturedSection: React.FC<Props> = ({
  imgUrl,
  title,
  description,
  list,
}) => {
  const image = getImage(imgUrl as ImageDataLike);

  return (
    <section>
      <div css={featuredSection.container} className="container">
        <figure css={featuredSection.figure}>
          {imgUrl ? (
            <GatsbyImage image={image!} alt="" css={featuredSection.image} />
          ) : (
            <UscVolLogo />
          )}
        </figure>
        <article css={featuredSection.info}>
          <header css={featuredSection.header}>
            <h2 css={featuredSection.title}>{title}</h2>
          </header>
          <section css={featuredSection.body}>
            <p css={featuredSection.description}>{description}</p>
            {list && (
              <ol>
                {list.map((item, index) => (
                  <li key={index + 1}>{item}</li>
                ))}
              </ol>
            )}
          </section>
        </article>
      </div>
    </section>
  );
};
