import { css } from "@emotion/react";

export const container = css`
  display: grid;
  row-gap: 1.5rem;
  padding-block-end: 5rem;

  @media screen and (min-width: 48rem) {
    grid-template-columns: var(--grid-columns-desktop);
    column-gap: $grid-gutter-width;
    align-items: center;
    padding-block: 7.75rem;
  }
`;

export const figure = css`
  @media screen and (min-width: 48rem) {
    grid-column: 1 / span 8;
    aspect-ratio: 638 / 358;
  }
`;

export const image = css`
  display: block;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

export const info = css`
  @media screen and (min-width: 48rem) {
    grid-column: 10 / span 6;
  }
`;

export const header = css`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
`;

export const logo = css`
  max-width: 100%;
  aspect-ratio: 1;
`;

export const title = css`
  font-size: clamp(var(--font-size-24), 1rem + 2vw, var(--font-size-36));
  color: var(--color-primary);
  line-height: 1.3;
`;

export const body = css`
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

export const description = css`
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
`;
